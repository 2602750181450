$primary: #efefef;
$secondary: #a81d36;
$light: #f4f4f4;
$danger: #ea4b35;
$success: #F9F9F9;
$warning: #9ea3a7;
$font-family-sans-serif: 'Raleway', sans-serif;
$header-height: 66px;
$custom-file-text: (
        en: "Browse",
        fr: "Parcourir"
);
@import "~bootstrap/scss/bootstrap";

$fa-font-path: "../webfonts";
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/brands";


body {
  font-family: 'Raleway', sans-serif;
  font-size: 16px;
}

.main {
  margin-top: $header-height;
}



.full-height {
  height: calc(100vh - #{$header-height})
}

.nav-item a:hover {
  color: #a81d36!important;
}

h1 {
  font-size: 3rem;
}


.hauteurcarr {
  height: calc(100vh - 66px);
}

.bg-slide {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.bg-slide1 {
  background-image: url("../../public/images/slide1.jpg");
}

.bg-slide2 {
  background-image: url("../../public/images/slide2.jpg");
}

.bg-slide3 {
  background-image: url("../../public/images/slide3.jpg");
}

.bg-slide4 {
  background-image: url("../../public/images/slide4.jpg");
}

.ombretext{
  text-shadow: black 0 0 10px;
}

.slide-text {
  margin-left: 15%;
}

.taille-sstitre {
  font-size: 1.3rem;
}

.interligne-titre {
  line-height: 1.5;
}

.resize-pres {
  font-size: 18px;
}

.card:hover, .ombre:hover {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.bord-article {
  border-color: rgba(0, 0, 0, 0.125)!important;
  border-width: 1px!important;
  border-style: solid!important;
}

.padd-article {
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.margin-ssbtn {
  margin-bottom: 20px;
}

.bg-article-min {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 200px;
}

.bg-article {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 300px;
}

.bg-presentation {
  background-image: linear-gradient(to bottom, rgba(0,0,0,.35), rgba(0,0,0,.35)), url("../../public/images/fond-presentation.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: calc(100vh - 66px);
}

.bg-prejudice {
  background-image: url("../../public/images/fond-prejudice_corporel.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: calc(100vh - 66px);
}

.bg-droitdutravail {
  background-image: url("../../public/images/fond-droitdutravail.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: calc(100vh - 66px);
}

.bg-droitperso {
  background-image: url("../../public/images/fond-droitpersonnalise.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: calc(100vh - 66px);
}

.bg-vosquestions {
  background-image: url("../../public/images/fond-vosquestions.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: calc(100vh - 66px);
}

.bg-honoraires {
  background-image: url("../../public/images/fond-vosquestions.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: calc(100vh - 66px);
}

.bg-explications {
  background-image: url("../../public/images/fond-explications.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: calc(100vh - 66px);
}

.bg-particuliers {
  background-image: url("../../public/images/fond-particuliers.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: calc(100vh - 66px);
}

.bg-societes {
  background-image: url("../../public/images/fond-societes.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: calc(100vh - 66px);
}

.padding-mob {
  margin-left: 10px;
  margin-right: 10px;
}

.marge-mob {
  margin-bottom: 48px;
}

.arrondi {
  border-radius: 30px;
}

.ombretest {
  box-shadow: 0 3px 12px -1px rgba(7, 10, 25, .2), 0 22px 27px -20px rgba(7, 10, 25, .2);
}

.maps-size {
  width: 300px;
}

.marge-lg {
  margin-top: 12px;
}

.marge-xl{
  margin-left: 20px;
  margin-right: 20px;
}

.taille-mob {
  font-size: 36px;
}

.lien-xs {
  font-size: 12px;
}

.mobile-size {
  font-size: 16px;
}

.police-honoraires {
  font-size: 14px;
}

.largeur-max {
  max-width: 750px;
}

.no-style {
  list-style: none;
  margin-left:0;
  padding-left:0;
  margin-bottom: 0;
}

.admin-content {
  padding-left: 250px;
}

.wrapper {
  display: flex;
  align-items: stretch;
}

#sidebar {
  width: 250px;
  height: 100vh;
  background: $secondary;
  color: #fff;
  position: fixed;
}

#sidebar .sidebar-header {
  padding: 20px;
  background: $secondary;
  border-bottom: 2px solid white;
}

#sidebar .sidebar-footer {
  padding: 20px;
  position: fixed;
  bottom: 0;
  width: 250px;
}

#sidebar ul p {
  color: #fff;
  padding: 10px;
}

#sidebar ul li a {
  padding: 10px;
  font-size: 1.1em;
  display: block;
}
#sidebar ul li a:hover {
  color: #741727;
  background: #fff;
}

#sidebar ul li.active > a, a[aria-expanded="true"] {
  color: #fff;
  background: #741727;
}

iframe {
  max-width: 100%;
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {

  .padding-mob {
    margin-left: 0;
    margin-right: 0;
  }

  .maps-size {
    width: 400px;
  }

}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {

  .decal-btn {
    position: relative;
    margin-top: 35.2px;
  }

  .margin-md {
    margin-left: 20px;
    margin-right: 20px;
  }

  .maps-size {
    width: 600px;
  }

  .marge-xl{
    margin-left: 40px;
    margin-right: 40px;
  }

  .lien-xs {
    font-size: 16px;
  }

  .mobile-size {
    font-size: 1.25rem;
  }

  .marge-right {
    margin-right: auto;
    margin-left: auto;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {

  .decalssmenu {
    position: relative;
    top: 50px;
    left: -8px;
  }

  .maps-size {
    width: 800px;
  }

  .marge-lg {
    margin-top: 0;
  }

  .taille-mob {
    font-size: 48px;
  }

}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {

  .maps-size {
    width: 600px;
  }

  .marge-xl{
    margin-left: 80px;
    margin-right: 0;
  }

  .taille-sstitre {
    font-size: 2rem;
  }

}


// Extra extra large devices (large desktops, 1600px and up)
@media (min-width: 1600px) {

  .maps-size {
    width: 700px;
  }

}
